<template src="./RecoveryAtIdC.html"></template>

<script>
import firstAdmissionService from '@/services/FirstAdmission';
export default {
  name: 'recovery-at-id-c',
  data () {
    return {
      emails: null,
      selectedEmail: null
    }
  },
  created() {
    firstAdmissionService.recoverAtId()
      .then(response => {
        this.emails = response.email
      }).catch((e)=>{
        console.error('error', e);
    })
  },
  methods: {
    validateEmail() {
      if (this.selectedEmail === null) {
            this.$router.push({path: `/recuperatidd`})
      } else {
        firstAdmissionService.postEmailToValidate(this.selectedEmail)
          .then(response => {
            if (response.emailValidated === false) {
              this.$router.push({path: '/recuperatiderror'})
            } else if (response.emailValidated === true) {
              this.$router.push({path: '/recuperatide'})
            }
          }).catch((e)=>{
            console.error('error', e);
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdC.scss">

</style>
